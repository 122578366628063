@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Zen+Dots&display=swap');

body.renaware {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif !important;
    box-sizing: border-box;
    background-color: #fff;
}

a {
    text-decoration: none;
}

.renaware ul,
.renaware li {
    margin: 0 !important;
    list-style-type: none !important;
    padding: 0 !important;
}

.bg_video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    overflow: hidden;
    right: 0;
}

.bg_video video {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
}

/*=== new site home ===*/
section.banner-section.new_home_banner {
    min-height: 100vh;
}

.new_home_banner .section_content h2 {
    color: #FFF;
    font-family: "Zen Dots", sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    /* 100% */
    letter-spacing: -3.6px;
    text-transform: uppercase;
}

.new_home_banner .section_content h3 {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: 'Work Sans', sans-serif !important;
}

.new_home_banner .section_content button.btn.btn-outline-primary {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    font-family: 'Work Sans', sans-serif !important;
    width: auto !important;
    min-width: auto !important;
    max-width: max-content !important;
}

section.medically-known.loss {
    border-top-right-radius: 0;
    background: #fff;
    border-top-left-radius: 0;
    padding: 64px 0;
}

.solutions_card img {
    border-radius: 24px;
    overflow: hidden;
    height: 384px;
    object-fit: cover;
}

.solution_card_inner h3 {
    font-size: 32px;
    font-family: 'Work Sans', sans-serif !important;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #fff;
}

.solution_card_inner p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Work Sans', sans-serif !important;
    color: #fff;
}


.solution_card_inner {
    border-radius: 0px 0px 24px 24px;
    background: rgba(3, 26, 64, 0.70);
    backdrop-filter: blur(2px);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.solutions_card {
    border-radius: 24px;
    box-shadow: 0px 16px 16px 0px rgba(38, 100, 157, 0.30);
    overflow: hidden;
}

.solutions_card.solutions_card_two .solution_card_inner h3,
.solutions_card.solutions_card_two .solution_card_inner p {
    color: #031A40;
}

.solutions_card.solutions_card_two.solutions_card_three img {
    width: 100%;
    height: 176px;
    border-radius: 0 0 24px 24px;
}


.solutions_card.solutions_card_two .solution_card_inner {
    border-radius: 0px 0px 24px 24px;
    background: rgba(241, 242, 246, 0.80);
    backdrop-filter: blur(8px);
}

.solutions_card.solutions_card_two.solutions_card_three .solution_card_inner {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    position: relative;
    background: #fff;
    border-radius: 24px 24px 0 0;
}

.card_title h2 {
    color: #031A40;
    font-size: 48px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.96px;
}


.btn.btn-primary,
.btn.btn-primary:active,
.btn.btn-primary:hover,
.btn.btn-primary:focus {
    width: 100%;
    padding: 16px 32px;
    background: #031A40;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    border: none;
    outline: none;
    box-shadow: none;
}

.btn.btn-outline-primary,
.btn.btn-outline-primary:disabled,
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus {
    width: 100%;
    text-align: center;
    gap: 10px;
    padding: 14.4px 32px;
    font-family: 'Work Sans', sans-serif !important;
    border: 2px solid #031A40;
    border-radius: 100px;
    background: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #031A40;
}


/*=== landing page ===*/
header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link,
header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 100px;
}

header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link:hover {
    background: #DF86DD;
}

header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    background: #5BC0EB;
    color: #031A40;
}

header.new_header .nav.navbar.navbar-expand-lg .btn.btn-primary {
    background: transparent;
}

header.new_header nav.navbar.navbar-expand-lg .btn.btn-primary:hover {
    background: #5BC0EB;
    color: #031A40;
}

nav.navbar.navbar-expand-lg .btn.btn-primary {
    background: transparent;
}

nav.navbar.navbar-expand-lg .dropdown-toggle::after {
    display: none;
}

header.new_header {
    padding: 27px 0 10px;
    position: absolute;
    /* overflow: hidden; */
    top: 0;
    z-index: 999;
    width: 100%;
}

.section_content h2 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -2.4px;
    color: #fff;
    font-family: 'Work Sans', sans-serif !important;
}

.section_content p {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #fff;
    font-family: 'Work Sans', sans-serif !important;
}

button.btn.btn-primary.btn-white {
    border: 2px solid #fff;
    background: transparent;
}

.section_content h6 {
    color: #5BC0EB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Work Sans', sans-serif !important;
    line-height: 20px;
}

section.banner-section {
    position: relative;
    padding: 90px 0 0;
    overflow: hidden;
}

.card_title h6 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: 'Work Sans', sans-serif !important;
    color: #031A40;
}


section.solution {
    position: relative;
    background: #fff;
    padding: 90px 0;
}

button.btn.btn-success {
    background: #DF86DD;
    border: 1px solid #DF86DD;
    padding: 12.5px 32px;
    border-radius: 100px;
    min-width: 160px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Work Sans', sans-serif !important;
    color: #031A40;
}

footer.footer.inner-footer {
    background: #031A40;
    padding: 64px 0;
    position: relative;
    overflow: hidden;
}

.footer_section h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    font-family: 'Work Sans', sans-serif !important;
    line-height: normal;
    color: #fff;
}

.footer_section ul li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    font-family: 'Work Sans', sans-serif !important;
}

.footer_section ul li:not(:last-child) {
    margin-bottom: 8px !important;
}

.copyright p {
    font-size: 14px;
    font-family: 'Work Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
}

/*=== renware page ===*/
header.new_header.dark-header {
    position: relative !important;
    padding: 18px 0;
}

header.new_header.dark-header.landing_header {
    position: absolute !important;
    z-index: 99;
}

.renware_banner .section_content h2 {
    font-size: 48px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -2.4px;
    color: #fff;
    /* text-transform: capitalize; */
}

.renware_card {
    max-width: 620px;
}


section.welness_section {
    min-height: 110vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-image: url(../../assets/wellness-way.jpeg);
    /* background-image: url(../../assets/wellness-way.jpeg); */
    background-size: cover;
    background-position: bottom center;
}

.welness_card {
    border-radius: 24px;
    /* background: rgba(3, 26, 64, 0.25); */
    padding: 24px;
    position: relative;
    overflow: hidden;
    background: rgba(3, 26, 64, 0.50);
}

.welness_card.card_title h2 {
    font-weight: 300;
    font-family: 'Work Sans', sans-serif !important;
}

.welness_card button.btn.btn-outline-primary {
    background: transparent;
    color: #fff;
    border-color: #fff;
}

.medically-known hr {
    border-color: #F1F2F6 !important;
    opacity: 1;
    background-color: #F1F2F6 !important;
}

section.key_feature_section {
    background: #fff;
    padding: 56px 0;
    position: relative;
    overflow: hidden;
}

.feature_card.card_title {
    border-radius: 24px;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.feature_card img {
    height: 180px;
    width: 180px;
    object-fit: contain;
}

.for_consumer ul li {
    border-radius: 24px;
    background: #F1F2F6;
    padding: 40px !important;
    font-size: 24px;
    font-style: normal;
    cursor: pointer;
    font-weight: 700;
    line-height: normal;
    color: #000;
    font-family: 'Work Sans', sans-serif !important;
}

.for_consumer ul li:not(:last-child) {
    margin-bottom: 21px !important;
}

.harness_multimode img {
    position: relative;
    left: -59px;
}

.biomark_circle {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: #E88280;
}

.biomark_circle.biomark_circle_2 {
    background: #ECBC3C;
}

.biomark_circle.biomark_circle_3 {
    background: #999500;
}

.biomark_circle.biomark_circle_4 {
    background: #03432B;
}

.biomark_circle.biomark_circle_5 {
    background: #7B4F9A;
}

.biomark_circle.biomark_circle_6 {
    background: #DCE451;
}

.biomark_circle.biomark_circle_7 {
    background: #7CB68E;
}

.biomark_circle.biomark_circle_8 {
    background: #FAA555;
}

.biomark_circle.biomark_circle_9 {
    background: #9C0E87;
}

.biomark_circle.biomark_circle_10 {
    background: #9976A2;
}

.biomark_circle.biomark_circle_11 {
    background: #F5E6A3;
}

.biomark_circle.biomark_circle_12 {
    background: #FCC79D;
}

section.banner-section.new_home_banner.renware_banner {
    height: auto;
    padding-top: 56px;
    min-height: auto;
}

.banner-section.new_home_banner.renware_banner.landingPage {
    padding-top: 0px;
    min-height: 100vh;
}

.key_feature_section.cognition {
    position: relative;
    background-color: transparent;
}

.font-300 {
    font-weight: 300 !important;
}

.Harness {
    background-color: #fff;
}

/*=== landing page ui ===*/
section.banner-section.renware_banner.landingPage.new_home_banner.d-flex.align-items-center {
    /* background-image: url(../images/landingBannerBg.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.we_are_sensify {
    position: relative;
    padding: 96px 0;
    background-color: #fff;
    overflow: hidden;
}



/*=== renware page ===*/
header.dark-header {
    position: relative;
    padding: 18px 0;
}

.renware_banner .section_content h2 {
    font-size: 48px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -2.4px;
    color: #fff;
    font-family: 'Work Sans', sans-serif !important;
    text-transform: none;
}

.renware_card {
    max-width: 620px;
}


section.welness_section {
    min-height: 110vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-image: url(../../assets/wellness-way.jpeg);
    /* background-image: url(../images/wellness-way.jpeg); */
    background-size: cover;
    background-position: bottom center;
}

.welness_card {
    border-radius: 24px;
    /* background: rgba(3, 26, 64, 0.25); */
    padding: 24px;
    position: relative;
    overflow: hidden;
    background: rgba(3, 26, 64, 0.50);
}

.welness_card.card_title h2 {
    font-weight: 300;
}

.welness_card button.btn.btn-outline-primary {
    background: transparent;
    color: #fff;
    border-color: #fff;
}

.medically-known hr {
    border-color: #F1F2F6 !important;
    opacity: 1;
}

section.key_feature_section {
    background: #fff;
    padding: 56px 0;
    position: relative;
    overflow: hidden;
}

.feature_card.card_title {
    border-radius: 24px;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.feature_card img {
    height: 180px;
    width: 180px;
    object-fit: contain;
}

.for_consumer ul li {
    border-radius: 24px;
    background: #F1F2F6;
    padding: 40px !important;
    font-size: 24px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    cursor: pointer;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.for_consumer ul li:not(:last-child) {
    margin-bottom: 21px !important;
}

.harness_multimode img {
    position: relative;
    left: -59px;
}

.biomark_circle {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: #E88280;
}

.biomark_circle.biomark_circle_2 {
    background: #ECBC3C;
}

.biomark_circle.biomark_circle_3 {
    background: #999500;
}

.biomark_circle.biomark_circle_4 {
    background: #03432B;
}

.biomark_circle.biomark_circle_5 {
    background: #7B4F9A;
}

.biomark_circle.biomark_circle_6 {
    background: #DCE451;
}

.biomark_circle.biomark_circle_7 {
    background: #7CB68E;
}

.biomark_circle.biomark_circle_8 {
    background: #FAA555;
}

.biomark_circle.biomark_circle_9 {
    background: #9C0E87;
}

.biomark_circle.biomark_circle_10 {
    background: #9976A2;
}

.biomark_circle.biomark_circle_11 {
    background: #F5E6A3;
}

.biomark_circle.biomark_circle_12 {
    background: #FCC79D;
}

section.banner-section.new_home_banner.renware_banner {
    height: auto;
    padding-top: 56px;
    min-height: calc(100vh - 87px);
}

section.banner-section.new_home_banner.scentware_benner.renware_banner {
    height: auto;
    padding: 56px 0;
    min-height: calc(67vh - 87px);
    background-image: url(../../assets/get_the_app.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

section.banner-section.new_home_banner.scentware_benner.renware_banner.custom_digital {
    background-image: url(../../assets/custom-digital-bg.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.banner-section.new_home_banner.renware_banner.landingPage {
    padding-top: 0px;
    min-height: 100vh;
}

.key_feature_section.cognition {
    position: relative;
    background-color: transparent;
}

.font-300 {
    font-weight: 300 !important;
}

.Harness {
    background-color: #fff;
}

.harness_section {
    /* background-image: url(../images/harness_bg.png); */
    background-image: url(../../assets/harness_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/*=== landing page ui ===*/
section.banner-section.renware_banner.landingPage.new_home_banner.d-flex.align-items-center {
    /* background-image: url(../images/landingBannerBg.png); */
    background-image: url(../../assets/landingBannerBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.we_are_sensify {
    position: relative;
    padding: 96px 0;
    background-color: #fff;
    overflow: hidden;
}

.we_are_sensify.specialize {
    padding: 56px 0;
}

.img_card {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(3, 26, 64, 0.30);
}


.dont_account a,
.card_title p {
    color: #000;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.anomia_icon {
    background: #031A40;
    border-radius: 20px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.anomia_icon.first_icon svg *,
.anomia_icon.first_icon svg {
    fill: none !important;
}

.anomia_icon:hover {
    background-color: #DF86DD;
    transition: 0.2s;
}

.anomia_icon svg,
.anomia_icon svg * {
    fill: #fff;
}

.anomia_icon:hover svg,
.anomia_icon:hover svg * {
    fill: #000;
}


.anomia_icon.first_icon:hover svg *,
.anomia_icon.first_icon:hover svg {
    stroke: #000 !important;
}

.feature_products {
    background-color: #fff;
    position: relative;
    padding: 64px 0;
}

section.research_partners {
    background: #fff;
    padding: 64px 0 64px 0;
    position: relative;
    overflow: hidden;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

ul.partner_icon {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

ul.partner_icon li {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-basis: 18.5%;
}



section.get_in_touch {
    padding: 96px 0;
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

form.query_form input.form-control,
form.query_form input.form-control:active,
form.query_form input.form-control:focus,
form.query_form input.form-control:hover,
form.query_form textarea,
form.query_form textarea:active,
form.query_form textarea:focus,
form.query_form textarea:hover {
    border-radius: 8px;
    border: 1px solid #031A40;
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
    color: #031A40;
    font-family: 'Work Sans', sans-serif;
    padding: 16px;
    height: 56px;
}

form.query_form textarea,
form.query_form textarea:active,
form.query_form textarea:focus,
form.query_form textarea:hover {
    height: 140px;
}

form.query_form input.form-control::placeholder,
form.query_form textarea::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
    color: #031A40;
    opacity: 0.5;
}

/* .cognition_img {
    height: 409px;
    width: 409px;
    overflow: hidden;
    border-radius: 409px;
    padding-top: 35px;
    background: #03529C;
}

.cognition_img img {
    width: 100%;
    height: 374px;
} */

.cognito_list {
    cursor: pointer;
}

.cognito_list .list_icon {
    width: 70px;
    height: 70px;
    flex: 0 0 auto;
    background-color: #5BC0EB;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cognito_list.active .list_icon {
    background-color: #EAD65B;
}

.cognito_list.active.green .list_icon {
    background-color: #57BA54;
}

.cognito_list.active.purple .list_icon {
    background-color: #DF86DD;
}

.cognito_list.active.red .list_icon {
    background-color: #EA4335;
}

.cognito_list_outer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cognito_list {
    border: 2px solid transparent;
    border-radius: 70px;
}

.cognito_list.active {
    border: 2px solid #EAD65B;
    border-radius: 70px;
}

.cognito_list.active.green {
    border: 2px solid #57BA54;
}

.cognito_list.active.purple {
    border: 2px solid #DF86DD;
}

.cognito_list.active.red {
    border: 2px solid #EA4335;
}

.draw_arrow {
    position: absolute;
    right: -4.3%;
    top: 40px;
    height: 30px;
}

.draw_arrow.green {
    top: 80px;
}

.draw_arrow.purple {
    top: 100px;
}

.draw_arrow.red {
    top: auto;
    bottom: 58px;
}

section.crucial {
    background: #fff;
    padding: 64px 0;
    border-radius: 64px;
}

section.smell_lose {
    padding-top: 64px;
    position: relative;
    overflow: hidden;
}

.smell_lose .medically_img {
    flex: 0 0 auto;
}

.smell_lose .medically_img img {
    width: 56px;
    height: 56px;
    object-fit: contain;
}

.modifiable {
    position: relative;
    background-color: #DF86DD;
    position: relative;
    overflow: hidden;
    padding-top: 80px;
}

.blue {
    color: #031A40 !important;
}


section.video_section {
    background: #fff;
    padding: 64px 0;
    position: relative;
    overflow: hidden;
}


.play_icon {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comprehensive-section {
    position: relative;
    overflow: hidden;
    padding: 64px 0;
    background-image: url(../../assets/comprehensive_bg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
}

/* .comprehensive-data1 {
    position: relative;
    overflow: hidden;
    padding: 64px 0;
    background-image: url(../../assets/comprehensive_bg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
} */

.graph_content p {
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 400;
    line-height: normal;
}

/*=== product banner ===*/

section.banner-section.product_banner {
    height: auto;
    padding-top: 0px;
    min-height: auto;
}

.product_banner .section_content h6 {
    color: #fff;
}

.product_banner .section_content p {
    font-size: 24px;
    font-family: 'Work Sans', sans-serif;
}

section.cost_effect.product_individuals {
    background: #fff;
    border-top-right-radius: 96px;
    border-top-left-radius: 96px;
    padding: 64px 0 40px;
    margin-bottom: -1px;
}

section.product_section {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
}

section.comprehensive {
    background: #fff;
    position: relative;
    overflow: hidden;
}

section.cost_effect {
    padding: 56px 0;
    position: relative;
    overflow: hidden;
}

.cost_effect .card_title h2 {
    font-weight: 300;
    color: #000;
}

section.sese_sec.sense_sec {
    padding: 56px 0;
    background: #fff;
    border-top-right-radius: 96px;
    border-top-left-radius: 96px;
    margin-bottom: -1px;
}

.card_title .dis h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #5BC0EB;
    font-family: 'Work Sans', sans-serif;
}

.card_title .dis h4 {
    color: #031A40;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Work Sans', sans-serif;
    line-height: normal;
}

.card_title .dis p {
    color: #031A40;
}

section.product_video {
    padding: 24px 0;
    background: #fff;
}

section.comprehensive_product_sec {
    padding: 24px 0 64px;
    border-bottom-right-radius: 96px;
    border-bottom-left-radius: 96px;
}

.product_card h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Work Sans', sans-serif;
    line-height: normal;
    color: #031A40;
}

.previous {
    transform: rotate(180deg);
    opacity: 0.25;
}

.product_card h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Work Sans', sans-serif;
    color: #031A40;
}

.product_card p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Work Sans', sans-serif;
    color: #031A40;
}

.cost_effect .card_title h2 {
    font-weight: 300;
    color: #000;
}


.key_feature_section,
section.medically-known.loss {
    margin-bottom: -1px;
}

.medically-known.loss .accordion-item {
    border: none;
    box-shadow: none;
    outline: none;
}

.accordion-button::after {
    filter: brightness(0);
}

.medically_card.card_title.accordion-button {
    background: transparent;
    border: none;
    box-shadow: none;
}

.feature_card.card_title h6 {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.key_feature_section .owl-carousel .owl-item {
    padding: 0 2px 7px;
}

/*intelivista page ===*/
header.inner_header .navbar ul.navbar-nav li.nav-item a.nav-link,
header.inner_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #031A40;
    border-radius: 100px;
}

.font-700 {
    font-weight: 700 !important;
}

.dark-header.new_header.inner_header button.btn.btn-primary.w-auto {
    background: #031A40;
}

.plan_card {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(78, 78, 78, 0.20);
    padding: 32px;
}

.card_title .plan_card h2 {
    color: #007AF2;
    font-family: 'Work Sans', sans-serif;
}

.card_title ul {
    padding-left: 14px !important;
}

.card_title .plan_card h6,
.card_title .plan_card li,
.card_title .plan_card h2,
.card_title .plan_card p {
    color: #007AF2;
    font-family: 'Work Sans', sans-serif;
}

.card_title ul li {
    list-style-type: disc !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #031A40;
    font-family: 'Work Sans', sans-serif;
    line-height: 20px;
}

.card_title .plan_card h6 {
    font-weight: 600;
}

.card_title.banner_content p {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    font-family: 'Work Sans', sans-serif;
    line-height: 26px;
    color: #031A40;
}

section.do_for_you {
    background: #D3EEFF;
    padding: 64px 0;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 96px;
    border-top-left-radius: 96px;
}


/*=== login page ===*/

section.login_page {
    min-height: 100vh;
    background: #ffffff7a;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* padding: 40px 0; */
}

.login_outer_inner {
    border-radius: 24px;
    overflow: hidden;
    background: #FFF;
    box-shadow: 0px 48px 48px 0px rgba(3, 26, 64, 0.30);
}

.login_bg {
    height: 100%;
    /* background: url(../images/login_bg.png); */
    background-image: url(../../assets/login_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 600px;
}

.login_outer {
    border-radius: 0px;
    background: #fff;
    position: relative;
    position: relative;
    width: 100%;
    z-index: 9;
    overflow: hidden;
}

.login_outer_m {
    width: 100%;
    max-width: 100%;
}


.form_feild form label {
    color: #031A40;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    font-family: 'Work Sans', sans-serif !important;
}

.password_field {
    max-width: 302px;
}

.password_remember label,
.password_remember ul li {
    color: #101A36;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    font-family: 'Work Sans', sans-serif !important;
    line-height: normal;
}

.password_remember ul li {
    list-style-type: disc !important;
}

.password_remember ul {
    padding-left: 20px !important;
}

.form_feild form input.form-control,
.form_feild form input.form-control:active,
.form_feild form input.form-control:focus,
.form_feild form input.form-control:hover,
.StripeElement,
.StripeElement:hover,
.StripeElement:focus,
.StripeElement:active {
    border: 2px solid #031A40;
    height: 56px;
    background: #fff;
    padding: 16px;
    font-family: 'Work Sans', sans-serif !important;
    outline: none;
    box-shadow: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    color: #000;
}

.StripeElement,
.StripeElement:hover,
.StripeElement:focus,
.StripeElement:active {
    padding-top: 20px;
}

.dont_account .btn.btn-primary,
.dont_account .btn.btn-primary:hover,
.dont_account .btn.btn-primary:active,
.dont_account .btn.btn-primary:focus {
    width: auto;
    font-family: 'Work Sans', sans-serif !important;
    padding: 9px 15px;
}

.sign_up .btn.btn-primary,
.sign_up .btn.btn-primary:hover,
.sign_up .btn.btn-primary:active,
.sign_up .btn.btn-primary:focus {
    background-color: #fff;
    color: #031A40;
}

.form_feild form input.form-control::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Work Sans', sans-serif !important;
    line-height: normal;
    color: #000;
}

.eye {
    position: absolute;
    top: 8.5px;
    bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
}

.forgot_pwd .eye {
    top: 16px;
    right: 17px;
}

.login_outer.form_feild .eye {
    position: absolute;
    top: 16px;
    bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 17px;
}

a.forgot_pwd {
    color: #03529C;
    font-size: 14px;
    font-style: normal;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 400;
    line-height: 20px;
}

.login_outer.form_feild .btn.btn-outline-primary,
.login_outer.form_feild .btn.btn-outline-primary .btn.btn-outline-primary:active,
.login_outer.form_feild .btn.btn-outline-primary .btn.btn-outline-primary:hover,
.login_outer.form_feild .btn.btn-outline-primary .btn.btn-outline-primary:focus {
    padding: 12px 32px;
}

.dont_account a,
.card_title p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Work Sans', sans-serif !important;
    line-height: 20px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: transparent !important;
    z-index: 9999;
}


/*=== sign up ===*/

section.login_page.sign_up_outer {
    background: #fff;
    padding: 40px 0;
}

.login_outer.sign_up_form {
    padding: 24px;
}

.login_outer.sign_up_form {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.pwd_note p,
.pwd_note ul li {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Work Sans', sans-serif !important;
    color: #7E7E7E;
}

.pwd_note ul li {
    list-style-type: disc !important;
}

.pwd_note ul li {
    flex: 0 0 auto;
    width: auto;
}

.login_outer.sign_up_form select,
.login_outer.sign_up_form .dropdown .btn.btn-secondary {
    background: #F1F2F6;
    height: 56px;
    border-radius: 8px;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 16px;
    font-size: 14px;
    font-style: normal;
    width: 100%;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 400;
    line-height: 20px;
    color: #000;
    text-align: left;
}

.login_outer.sign_up_form ul.dropdown-menu {
    width: 100%;
    border: none;
    background: #F1F2F6;
    border-radius: 0 0 8px 8px;
    transition: 0.5s;
    overflow: hidden;
    transform: translate3d(0px, 56px, 0px) !important;
}

.login_outer.sign_up_form ul.dropdown-menu li a {
    padding: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000;
}

.login_outer.sign_up_form .dropdown .btn.btn-secondary.show {
    border-radius: 8px 8px 0 0;
}

.login_outer.sign_up_form .dropdown .btn.btn-secondary svg {
    transform: rotate(0deg);
    transition: 0.5s;
}

.login_outer.sign_up_form .dropdown .btn.btn-secondary.show svg {
    transform: rotate(180deg);
    transition: 0.5s;
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
    z-index: 999999;
}

a.product_button:hover svg {
    background: #007AF2;
    border-radius: 9px;
}

.login_outer.sign_up_form ul.dropdown-menu li a:hover,
.login_outer.sign_up_form ul.dropdown-menu li a:active,
.login_outer.sign_up_form ul.dropdown-menu li a:focus {
    background: #007AF2;
    transition: 0.5s;
    color: #fff;
}

.login_outer_inner input[type="text"] {
    margin: 0 !important;
}

.login_outer.sign_up_form .dropdown-toggle::after {
    display: none;
}

.select_arrow {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 0;
}

.pwd_note ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 14px !important;
}

.card_title ul li {
    list-style-type: disc !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #031A40;
    font-family: 'Work Sans', sans-serif !important;
    line-height: 20px;
}

.card_title ul {
    padding-left: 14px !important;
}

::-webkit-inner-spin-button {
    display: none;
}

.reset_success:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #5BC0EB;
    max-width: 600px;
}

.reset_success .container {
    position: relative;
    z-index: 999;
}

.reset_success:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #5BC0EB;
    max-width: 44.5vw;
}

.login_outer.sign_up_form form {
    width: 100%;
    min-width: initial;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
    background-color: #5BC0EB;
    color: #031A40;
    outline: none;
    box-shadow: none;
}

.welness_card button.btn.btn-outline-primary:hover,
.welness_card button.btn.btn-outline-primary:active,
.welness_card button.btn.btn-outline-primary:focus {
    background-color: #DF86DD;
    border-color: #DF86DD;
    color: #031A40;
    outline: none;
    box-shadow: none;
}

.welness_card button.btn.btn-outline-primary:hover svg path,
.welness_card button.btn.btn-outline-primary:active svg path,
.welness_card button.btn.btn-outline-primary:focus svg path {
    fill: #031A40;
}

.btn.btn-primary:active,
.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background-color: #DF86DD !important;
    border-color: #DF86DD !important;
    color: #031A40 !important;
    outline: none;
    box-shadow: none;
}

.btn.btn-outline-primary:active,
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus {
    background-color: #5BC0EB !important;
    border-color: #5BC0EB !important;
    color: #031A40 !important;
    outline: none;
    box-shadow: none;
}

button.close_button {
    position: absolute;
    top: -25px;
    right: -25px;
    border-radius: 16px;
    background: #F1F2F6;
    width: 48px;
    height: 48px;
    outline: none;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


/*=== check out page ===*/

ul.card_breadcrumb li a {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    text-decoration: none;
}

ul.card_breadcrumb li {
    padding: 4px 16px !important;
    display: inline-block;
    border-radius: 0px 0px 0px 0px;
}

ul.card_breadcrumb li:not(:first-child) {
    border-radius: 0;
    margin-left: 20px !important;
}

ul.card_breadcrumb li.active,
ul.card_breadcrumb li:hover {
    background: #DF86DD;
    cursor: pointer;
}

.cart_sec .card_title h6 b {
    font-weight: 500;
}

.form_feild form sup {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 12px;
    color: #031A40;
    position: relative;
    top: 8px;
    display: block;
}

input.form-check-input {
    height: 24px !important;
    width: 24px !important;
    padding: 0 !important;
    border: 2px solid #031A40;
}

.summary_card {
    border-radius: 24px;
    background: #FFF;
    padding: 24px 32px;
    box-shadow: 0px 16px 32px 0px rgba(38, 100, 157, 0.20);
}

.summary_card .card_title h6 b {
    font-weight: 700;
}

.summary_card hr {
    border-top-color: #D9D9D9;
    opacity: 1;
    background-color: transparent;
}

.summary_card p {
    color: #031A40 !important;
}

section.cart_sec.inner-banner-section.IntelliVista_banner {
    padding-bottom: 80px;
}

.order_confirm .product_img img {
    height: 200px;
    object-fit: cover;
    border-radius: 16px;
}

section.cart_sec.order_confirm.inner-banner-section.order-confirm.IntelliVista_banner {
    padding-bottom: 56px;
}

.confirmation {
    border-color: #031A40 !important;
}

.subscription_detail p span {
    flex: 0 0 auto;
    width: 46%;
}

.subscription_detail p {
    text-wrap: nowrap;
}

.card_title p.bill_time {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #9C9C9C;
}

.login_outer.sign_up_form {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.cost_effect .card_title h2 {
    font-weight: 300;
    color: #000;
}

.card_title .plan_card h2 {
    color: #007AF2;
}

.card_title .plan_card h6 {
    font-weight: 600;
}

.card_title .plan_card form p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #7E7E7E;
}

.custom_plan p {
    flex: 0 0 auto;
    min-width: 34%;
}

.login_page form {
    width: 100%;
    min-width: initial;
}

/* header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link,
header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #031A40;
    border-radius: 100px;
}

header.new_header .navbar ul.navbar-nav li.nav-item:hover a.nav-link,
header.new_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #031A40;
    background-color: #5BC0EB;
} */
.card_title.user_profile_list ul li {
    padding: 8px !important;
}

.card_title.user_profile_list ul {
    padding: 0 !important;
}

.card_title.user_profile_list ul,
.card_title.user_profile_list ul li {
    list-style-type: none !important;
}

.confirmation_modal {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 48px 48px 0px rgba(3, 26, 64, 0.30);
}

.user_profile_mobile {
    min-height: calc(100vh - 78px);
}

.product_img.checkoutProductImage img {
    height: 384px;
    object-fit: cover;
    border-radius: 24px;
}

section.login_page.RenAwareModal .login_outer_inner {
    padding: 32px 48px;
    max-width: 1092px;
    margin: auto;
}

.login_page form label {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #000;
}

section.login_page.RenAwareModal {
    max-height: 100vh;
    overflow: auto;
}


.section_sep:after,
.section_sep:before {
    position: absolute;
    content: "";
    left: -24px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #5BC0EB;
    width: 2px;
    height: 48px;
}

.section_sep:before {
    right: -24px;
    left: auto;
}



.range_input .MuiSlider-root,
.MuiSlider-rail {
    /* appearance: none; */
    width: 100%;
    height: 8px !important;
    border-radius: 4px !important;
    background: #F1F2F6 !important;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    max-width: 600px;
    margin: auto;
    padding: 0;
}

/* 
.range_input input:hover {
    opacity: 1;
}

.range_input input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: #031A40;
    border: 4px solid #5BC0EB;
    cursor: pointer;
}
*/
.MuiSlider-track {
    background-color: #5BC0EB !important;
    height: 8px !important;
    border-radius: 8px !important;
}

.range_input .MuiSlider-thumb {
    width: 32px;
    height: 32px;

    margin-top: -11px !important;
    border-radius: 50px;
    background: #031A40;
    border: 4px solid #5BC0EB;
    cursor: pointer;
}

.progress {
    background: linear-gradient(to right, #5BC0EB 0%, #5BC0EB 40%, #F1F2F6 40%, #F1F2F6 100%);
    /* border: solid 2px #5BC0EB; */
    border-radius: 28px !important;
    height: 8px !important;
    width: 600px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    overflow: visible !important;
}

.progress::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: #031A40;
    border: 4px solid #5BC0EB;
    cursor: pointer;
}

@media (min-width:960px) {
    .navbar-collapse.d-lg-none.d-md-none {
        display: none !important;
    }
}

@media (max-width:1400px) {
    .draw_arrow {
        right: -12.3%;
    }

    .cognito_list_outer {
        gap: 15px;
    }


}


@media (max-width:1200px) {

    .card_title h2 {
        font-size: 35px;
        line-height: 40px;
    }

    .login_outer {
        padding: 32px 32px;
    }

    .section_sep:after,
    .section_sep:before {
        display: none;
    }

    .btn.btn-outline-primary,
    .btn.btn-outline-primary:active,
    .btn.btn-outline-primary:hover,
    .btn.btn-outline-primary:focus {
        font-size: 14px;
    }

    .btn.btn-primary,
    .btn.btn-primary:active,
    .btn.btn-primary:hover,
    .btn.btn-primary:focus {
        padding: 16px 16px;
        font-size: 14px;
    }
}

@media (max-width:1190px) {
    .draw_arrow {
        display: none;
    }

    .we_are_sensify,
    .key_feature_section {
        margin-bottom: -1px;
    }

    br {
        display: none;
    }

    .feature_products,
    section.get_in_touch {
        margin-top: -1px;
    }

    .we_are_sensify,
    .feature_products,
    section.research_partners,
    section.get_in_touch,
    section.medically-known.loss,
    section.key_feature_section,
    .we_are_sensify.specialize {
        padding: 46px 0;
    }

    section.welness_section {
        min-height: 80vh;
    }

    ul.partner_icon {
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;
        overflow: scroll;
    }
}

@media (max-width:1200px) and (min-width:992px) {

    .footer_section h3 {
        font-size: 20px;
    }

    .copyright p {
        font-size: 10px;
    }
}


@media (max-width:1400px) and (min-width:1100px) {
    .login_outer_m.position-relative.px-lg-4.px-md-0.px-4.py-lg-0.py-md-0.py-4 {
        padding: 0 !important;
    }
}

@media (max-width:1040px) {
    section.banner-section.new_home_banner.renware_banner.RaInner_banner {
        min-height: calc(90vh - 87px);
        padding-bottom: 0;
    }

    .feature_card.card_title h6 {
        min-height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
    }

    section.banner-section.product_banner {
        padding-top: 60px;
    }
}

button.navbar-toggler {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

header ul.dropdown-menu {
    background: #fff;
    overflow: hidden;
    outline: none;
    box-shadow: none;
    border: none;
}

header ul.dropdown-menu li a {
    color: #000;
    padding: 12px 15px;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

header ul.dropdown-menu .dropdown-item:hover,
header ul.dropdown-menu .dropdown-item:focus {
    color: #fff;
    background-color: #007AF2;
}

/*=== my profile ===*/
section.get_the_app {
    padding: 96px 0;
    background-image: url(../../assets/get_the_app.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}

.app_card {
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 64px 72px 0px #031A40;
}

.app_card.account_card {
    padding: 40px;
}


.app_card.account_card {
    padding: 40px;
}

.account_card .nav.nav-tabs {
    border: none !important;
    width: 100%;
}

.account_card .nav.nav-tabs .nav-item .nav-link {
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #000;
    border-radius: 4px;
    text-align: left;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
}

.profile_border hr {
    width: 1px;
    height: 100%;
    opacity: 1;
    background: #D9D9D9;
}

.account_card .nav.nav-tabs .nav-item .nav-link.active,
.account_card .nav.nav-tabs .nav-item:hover .nav-link {
    background: #0096FF;
    color: #fff;
    font-weight: 700;
}

.pssword_field {
    width: 100%;
    max-width: 472px;
    flex: 0 0 auto;
}

.btn.btn-outline-primary.disable,
.profile_card_innner .disable label {
    opacity: 0.25;
}

.payment_card {
    border-radius: 8px;
    border: 1px solid #03529C;
    background: #FAFAFA;
}

.payment_icon_card {
    border: 1px solid #F1F2F6;
    border-radius: 4px;
    background: #fff;
    width: 49px;
}

.order_table .table tr th {
    overflow: hidden;
    color: #B9B9BA;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 24px;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
}

.order_table table tbody tr td {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
    color: #101A36;
    padding: 12px;
    border: none;
    background: #FAFAFA;
}

.order_table table tbody tr td:first-child {
    border-radius: 8px 0 0 8px;
}

.order_table table tbody tr td:last-child {
    border-radius: 0 8px 8px 0px;
}

.order_table table tbody tr td:nth-child(2) {
    width: 207px;
    max-width: 207px;
}

.payment_card .btn.btn-primary,
.payment_card .btn.btn-outline-primary {
    border-radius: 8px !important;
}

/*=== check box ===*/

.checkbox-wrapper-2 .authenticate_check {
    appearance: none;
    background-color: #E2E2E2;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 22px;
    margin: 0;
    position: relative;
    padding: 0 !important;
    width: 40px;
}

.checkbox-wrapper-2 .authenticate_check::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .authenticate_check,
.checkbox-wrapper-2 .authenticate_check::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .authenticate_check::after {
    background-color: #1D1A2F;
    border-radius: 50%;
    content: "";
    height: 12px;
    left: 3px;
    position: absolute;
    top: 0;
    width: 12px;
    bottom: 0;
    margin: auto;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .authenticate_check:hover {
    background-color: #E2E2E2;
    transition-duration: 0s;
}

.checkbox-wrapper-2 .authenticate_check:checked {
    background-color: #0096FF;
}

.checkbox-wrapper-2 .authenticate_check:checked::after {
    background-color: #fff;
    left: 23px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

.checkbox-wrapper-2 .authenticate_check:checked:hover {
    background-color: #0096FF;
}


.profile_card_innner label {
    flex: 0 0 auto;
    min-width: 170px;
}

.profile_card_innner h3 {
    font-weight: 300;
    color: #000;
}

.profile_card_innner p,
.profile_card_innner label {
    color: #000;
    font-weight: 500;
}

.profile_card_innner input {
    border-radius: 8px;
    border: 1px solid #B9B9BA;
    background: #fff;
    padding: 12px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    line-height: normal;
    color: #101A36;
    width: 100%;
    max-width: 302px;
}

.account_card input[type="text"] {
    margin: 0 !important;
}

.profile_card_innner input::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    line-height: normal;
    color: #101A36;
    opacity: 0.6;
}

.order_table .table,
.order_table .table * {
    border: none;
}

button.btn.btn-outline-primary.subscription_status {
    color: #0096FF;
    border-color: #0096FF;
}



@media (max-width:992px) {
    .cognito_list_outer {
        display: none;
    }

    section.login_page.forgot_pwd {
        min-height: auto;

    }

    header.inner_header .navbar ul.navbar-nav li.nav-item a.nav-link,
    header.inner_header .navbar ul.navbar-nav li.nav-item a.nav-link.active {
        color: #fff;
        border-radius: 100px;
    }

    header.dark-header.new_header.inner_header {
        background-color: #031A40;
        padding: 9px 0;
    }

    .dont_account button.btn.btn-primary.w-auto {
        width: 100% !important;
        text-align: center !important;
    }

    .login_page .dont_account .btn.btn-primary,
    .login_page .dont_account .btn.btn-primary:hover,
    .login_page .dont_account .btn.btn-primary:active,
    .login_page .dont_account .btn.btn-primary:focus {
        width: auto !important;
    }
}

.product_img img {
    height: 175px;
    object-fit: cover;
    border-radius: 24px;
}

@media (max-width:767px) {
    header.new_header.dark-header.landing_header {
        position: relative !important;
        z-index: 99;
    }

    section.login_page.RenAwareModal .login_outer_inner {
        padding: 24px 24px;
    }
    ul.card_breadcrumb li a
    {
        font-weight: 600;
    }
    .app_card {
        border-radius: 0;
        background: #fff;
        box-shadow: none;
    }

    .login_outer {
        padding: 0;
    }

    .profile_card_innner p,
    .profile_card_innner label {
        color: #000;
        font-weight: 700;
    }

    .product_img.checkoutProductImage img {
        height: auto;
        object-fit: cover;
        border-radius: 24px;
    }

    .summary_card .card_title h6 b {
        font-weight: 300;
    }

    .summary_card {
        border-radius: 0;
        background: #FFF;
        padding: 0px 0px;
        box-shadow: none;
    }

    .inner-banner-section.IntelliVista_banner .col-12 {
        padding: 0;
    }

    .renware_banner .section_content h2 {
        font-size: 24px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0;
    }

    .login_outer_m {
        width: 100%;
        max-width: 100%;
    }


    .login_outer_m:after {
        display: none;
    }

    .banner-section.new_home_banner.renware_banner.landingPage {
        padding: 0px 12px;
        min-height: 90vh;
    }

    section.key_feature_section {
        padding: 24px 12px;
    }

    .card_title h2 {
        font-size: 24px;
        line-height: normal;
        letter-spacing: 0;
        font-weight: 700;
    }

    .we_are_sensify,
    .we_are_sensify.specialize {
        padding: 24px 12px;
    }

    section.we_are_sensify.home_we_are img {
        height: 160px;
        width: 160px;
    }

    .feature_card.card_title h6 {
        font-weight: 700;
    }

    .we_are_sensify .card_title h6,
    .get_in_touch .card_title h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000;
    }

    section.Harness {
        padding: 32px 12px !important;
    }

    section.feature_products {
        padding: 32px 12px;
    }


    section.research_partners {
        padding: 48px 12px;
    }

    section.research_partners,
    section.get_in_touch {
        padding: 48px 12px 24px;
    }

    footer.footer.inner-footer {
        padding: 32px 12px;
    }

    .solution_card_inner h3 {
        font-size: 14px;
        font-weight: 700;
    }

    section.banner-section.new_home_banner.renware_banner {
        height: auto;
        padding: 24px 12px;
        min-height: calc(90vh - 87px);
    }

    .renaware .container-fluid {
        width: 100%;
    }

    section.banner-section.new_home_banner.scentware_benner.renware_banner {
        padding: 24px 12px;
        min-height: calc(90vh - 87px);
        background-image: none;
    }


    .renware_banner .card_title h6,
    .biomarker .card_title h6,
    .solution .card_title h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    section.crucial {
        padding: 24px 12px;
        border-radius: 0;
    }

    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 16px;
    }

    section.smell_lose {
        padding: 24px 12px;
        position: relative;
        overflow: hidden;
    }

    section.modifiable {
        padding: 24px 12px 0;
    }

    .modifiable .card_title h2 {
        font-weight: 500;
    }

    .biomarker {
        padding-left: 12px;
        padding-right: 12px;
    }

    .comprehensive-section {
        padding: 0px 0 24px;
        background-image: none;
        background-color: #fff;
    }

    .comprehensive-section .card_title {
        padding: 32px 12px 0;
    }

    .comprehensive-section .card_title .btn,
    .harness_section .btn,
    .solution .btn {
        width: 100% !important;
    }

    section.video_section {
        padding: 24px 12px;
    }

    section.harness_section {
        padding: 100px 12px !important;
    }

    .crucial .carousel-inner img {
        height: 129px;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
    }

    section.banner-section.new_home_banner.renware_banner.RaInner_banner {
        padding-top: 48px;
    }

    section.welness_section {
        min-height: 80vh;
        align-items: flex-start;
        padding: 24px 12px;
    }

    .welness_card button.btn.btn-outline-primary {
        width: 100% !important;
    }

    section.medically-known.loss {
        padding: 24px 12px;
    }

    section.solution {
        padding: 24px 12px;
    }

    .solutions_card img {
        height: 244px;
    }

    section.banner-section.product_banner {
        padding-top: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }

    section.cost_effect.product_individuals {
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
        padding: 32px 12px 32px;
        margin-bottom: -1px;
    }

    section.comprehensive_product_sec {
        padding: 32px 12px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
    }

    section.cost_effect {
        padding: 64px 0 64px;
    }

    .product_card h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        color: #031A40;
    }

    .product_card .product_price h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        color: #031A40;
    }

    section.cost_effect.remote {
        padding: 32px 12px;
    }

    section.sese_sec.sense_sec {
        padding: 32px 12px;
        background: #fff;
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
        margin-bottom: -1px;
    }

    section.product_video {
        padding: 32px 12px;
        background: #fff;
    }

    .product_card a,
    .product_card .btn {
        width: 45.9% !important;
        height: 56px;
        white-space: nowrap;
    }


    /* .product_card .btn {
        width: 47.974% !important;
        height: 56px;
    } */

    .product_card a .btn {
        width: 100% !important;
    }

    section.do_for_you {
        padding: 56px 12px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
    }

    .card_title.banner_content p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    section.cost_effect.intelivista {
        padding: 32px 12px;
    }

    section.login_page.checkout_page.inner-banner-section.sign_up_outer {
        padding-left: 12px;
        padding-right: 12px;
    }

    section.login_page.sign_up_outer {
        background: #fff;
        padding: 24px 0 24px 0;
        height: auto;
        min-height: auto;
    }

    .product_img img {
        width: 148px;
        height: 148px;
        object-fit: cover;
    }

    .card_title.cart_sec_product h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .profile_card_innner input {
        width: 100%;
        max-width: 100% !important;
    }

    .profile_card_innner .password_field {
        max-width: 100% !important;
    }

    .profile_card_innner input {
        height: 56px;
    }

    .card_title.user_profile_list .profile_mobile_list ul li {
        padding: 0 !important;
        list-style-type: disc !important;
    }

    .card_title.user_profile_list .profile_mobile_list ul {
        padding-left: 20px !important;
    }

    section.get_the_app {
        padding: 0 0 24px 0;
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    section.get_the_app.user_profile {
        padding: 32px 0;
    }

    .app_card.account_card {
        padding: 0 12px;
        box-shadow: none;
        background: transparent;
    }

    button.close_button {
        top: -15px;
        right: -15px;
    }

    section.key_feature_section.solution_custom {
        padding: 48px 12px;
    }
}

.btn.btn-outline-primary button {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.previous.active {
    opacity: 1.25;
}

.previous_button.active {
    opacity: 1.25;
}

.icon:hover path {
    stroke: #5BC0EB;
}

.confirmation_modal {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 48px 48px 0px rgba(3, 26, 64, 0.30);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #5BC0EB;
    ;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

header.checout .navbar ul.navbar-nav li.nav-item a.nav-link,
header.checout .navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #031A40;
    border-radius: 100px;
}

.login_outer_inner.query_form_outer {
    max-width: 610px !important;
}

.order_table.user_table {
    max-height: 300px;
    overflow: auto;
}

/* for loader */

.page_loader {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #031a4089;
    z-index: 99;
}